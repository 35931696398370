import { Moment } from 'moment';
import { LOCATION_CHANGE } from 'connected-react-router';
import qs from 'query-string';
import { Contact, CounterpartyAddress } from '../contact/types';
import { Cargo } from '../cargo/types';
import {
  ADD_CALCULATION_RESULTS,
  AdditionalServiceStatus,
  CALC_AVAILABLE_FILTERS,
  CalculationActions,
  CalculationFilter,
  CalculationSide,
  CalculationSideCast,
  CHECK_CURRENT_FILTERS,
  CurrentCalculationFilter,
  RESET_CALCULATION,
  SET_AMO_ID,
  SET_CALCULATION_CARGO,
  SET_CALCULATION_DONE,
  SET_CALCULATION_POLLING_TIMER,
  SET_CALCULATION_PRE_STATE,
  SET_CALCULATION_RECEIVER,
  SET_CALCULATION_RECEIVER_CAST,
  SET_CALCULATION_RESULTS,
  SET_CALCULATION_RUNNING,
  SET_CALCULATION_SENDER,
  SET_CALCULATION_SENDER_CAST,
  SET_CALCULATION_TASK_ID,
  SET_CURRENT_ORDERING,
  SET_DATE_FROM,
  SET_RECEIVER_ADDRESS,
  SET_RECEIVER_CONTACT,
  SET_SENDER_ADDRESS,
  SET_SENDER_CONTACT,
  SingleRate,
  SWITCH_CURRENT_FILTER,
  UPDATE_CALCULATION_RECEIVER_CAST,
  UPDATE_CALCULATION_SENDER_CAST,
  UPDATE_CURRENT_DELIVERY_SERVICE_FILTER,
  UPDATE_SERVICES_FILTER,
  UPDATE_CURRENT_DELIVERY_WAY_FILTER,
  SET_CURRENT_RATE,
  SET_RECALCULATION_RESULTS,
  ADD_RECALCULATION_RESULTS,
  SET_RECALCULATION_RUNNING,
  SET_RECALCULATION_DONE,
  SET_RECALCULATION_TASK_ID,
  RESET_BASE_CALCULATION,
} from './types';

export interface CalculationState {
  senderContact: Contact | null,
  receiverContact: Contact | null,
  senderAddress: CounterpartyAddress | null,
  receiverAddress: CounterpartyAddress | null,
  cargo: Cargo | null,
  dateFrom: Moment | null,
  calculationRunning: boolean,
  taskId: string | null,
  results: SingleRate[],
  pollingTimer: any,
  preState: 'sender' | 'receiver' | 'cargo' | 'new_sender' | 'new_receiver',
  sender: CalculationSide | null,
  receiver: CalculationSide | null,
  senderCast: CalculationSideCast | null,
  receiverCast: CalculationSideCast | null,
  currentFilters: CurrentCalculationFilter,
  availableFilters: CalculationFilter,
  currentOrdering: 'price' | 'minDays',
  calculationDone: boolean,
  amoId: null | string | number,
  shareEmail: null | string,
  currentRate: SingleRate | null,

  recalculationRunning: boolean,
  recalcTaskId: string | null,
  recalcResults: SingleRate[],
  recalculationDone: boolean,
}

const initialState: CalculationState = {
  senderContact: null,
  receiverContact: null,
  cargo: null,
  dateFrom: null,
  calculationRunning: false,
  taskId: null,
  results: [],
  pollingTimer: null,
  preState: 'sender',
  senderAddress: null,
  receiverAddress: null,
  sender: null,
  receiver: null,
  senderCast: null,
  receiverCast: null,
  currentFilters: {
    deliveryServices: {
      touched: false,
      values: [],
      open: true,
    },
    services: {
      touched: false,
      values: [],
      open: true,
    },
    deliveryWay: {
      touched: false,
      values: [],
      open: true,
    }
  },
  availableFilters: {
    deliveryServices: [],
    services: [],
    deliveryWay: []
  },
  currentOrdering: 'minDays',
  calculationDone: false,
  amoId: null,
  shareEmail: null,
  currentRate: null,

  recalculationRunning: false,
  recalcTaskId: null,
  recalcResults: [],
  recalculationDone: false,
};

function addRates(currentRates: SingleRate[], newRates: SingleRate[]) {
  const curIds = currentRates.map((_: SingleRate) => _.rateResultId);
  // newRates.forEach((rate) => {
  //   rate.rateSpeed = choose([RateSpeedChoices.DAILY, RateSpeedChoices.BY_TIME, RateSpeedChoices.DEFAULT]);
  //   rate.deliveryTime = choose(['13:00', '14:00', '18:00']);
  // });
  return [...currentRates, ...newRates.filter((_: SingleRate) => !curIds.includes(_.rateResultId))];
}

function calcFilter(currentRates: Array<SingleRate>): CalculationFilter {
  const deliveryServices: Array<string> = [];
  const services: string[] = [];
  const deliveryWay: string[] = [];
  currentRates.forEach((rate) => {
    if (!deliveryServices.includes(rate.deliveryService)) deliveryServices.push(rate.deliveryService);
    if (!deliveryWay.includes(rate.rateType)) deliveryWay.push(rate.rateType);
    rate.additionalServices.forEach((service) => {
      if (service.status !== AdditionalServiceStatus.NOT_AVAILABLE && !services.includes(service.code)) {
        services.push(service.code);
      }
    });
  });
  return {
    deliveryServices,
    services,
    deliveryWay
  };
}

function checkCurrentFilter(currentCalculationFilter: CurrentCalculationFilter, availableFilters: CalculationFilter) {
  const tmp = { ...currentCalculationFilter };
  if (!tmp.deliveryServices.touched) tmp.deliveryServices.values = availableFilters.deliveryServices;
  if (!tmp.deliveryWay.touched) tmp.deliveryWay.values = availableFilters.deliveryWay.includes('d2d') ? ['d2d'] : availableFilters.deliveryWay;
  return tmp;
}

function switchFilter(currentFilters: CurrentCalculationFilter, filter: 'deliveryServices' | 'services'): CurrentCalculationFilter {
  const tmp = { ...currentFilters };
  tmp[filter].open = !tmp[filter].open;

  return tmp;
}

const findRate = (search: string, rateList: SingleRate[]) => {
  const { result } = qs.parse(search);
  if (result) return rateList.find(({ rateResultId }) => rateResultId === result) ?? null;
  return null;
};

const findRateByRateResultId = (id: string, rateList: SingleRate[]) => {
  return rateList.find(({ rateResultId }) => rateResultId === id) ?? null;
};

export default function (state = initialState, action: CalculationActions): CalculationState {
  switch (action.type) {
    case SET_SENDER_CONTACT:
      return { ...state, senderContact: action.contact };
    case SET_RECEIVER_CONTACT:
      return { ...state, receiverContact: action.contact };
    case RESET_CALCULATION:
      return { ...initialState };
    case SET_CALCULATION_CARGO:
      return { ...state, cargo: action.cargo };
    case SET_DATE_FROM:
      return { ...state, dateFrom: action.dateFrom };
    case SET_CALCULATION_RUNNING:
      return { ...state, calculationRunning: action.running };
    case SET_CALCULATION_TASK_ID:
      return { ...state, taskId: action.taskId };
    case SET_CALCULATION_RESULTS:
      if (!state.taskId) return { ...state };
      return { ...state, results: action.results };
    case ADD_CALCULATION_RESULTS:
      return { ...state, results: addRates(state.results, action.results) };
    case SET_CALCULATION_POLLING_TIMER:
      return { ...state, pollingTimer: action.pollingTimer };
    case SET_CALCULATION_PRE_STATE:
      return { ...state, preState: action.preState };
    case SET_SENDER_ADDRESS:
      return { ...state, senderAddress: action.address };
    case SET_RECEIVER_ADDRESS:
      return { ...state, receiverAddress: action.address };
    case SET_CALCULATION_SENDER:
      return { ...state, sender: action.sender };
    case SET_CALCULATION_RECEIVER:
      return { ...state, receiver: action.receiver };
    case SET_CALCULATION_SENDER_CAST:
      return { ...state, senderCast: action.cast };
    case SET_CALCULATION_RECEIVER_CAST:
      return { ...state, receiverCast: action.cast };
    case UPDATE_CALCULATION_SENDER_CAST:
      return { ...state, senderCast: { ...state.senderCast, ...action.cast } };
    case UPDATE_CALCULATION_RECEIVER_CAST:
      return { ...state, receiverCast: { ...state.receiverCast, ...action.cast } };
    case CALC_AVAILABLE_FILTERS:
      return { ...state, availableFilters: calcFilter(state.results) };
    case CHECK_CURRENT_FILTERS:
      return { ...state, currentFilters: checkCurrentFilter(state.currentFilters, state.availableFilters) };
    case UPDATE_CURRENT_DELIVERY_SERVICE_FILTER:
      return {
        ...state,
        currentFilters: {
          ...state.currentFilters,
          deliveryServices: {
            ...state.currentFilters.deliveryServices,
            touched: true,
            values: action.deliveryServices,
          },
        },
      };
    case UPDATE_CURRENT_DELIVERY_WAY_FILTER:
      return {
        ...state,
        currentFilters: {
          ...state.currentFilters,
          deliveryWay: {
            ...state.currentFilters.deliveryWay,
            touched: true,
            values: action.deliveryWay,
          },
        },
      };
    case SET_CURRENT_ORDERING:
      return { ...state, currentOrdering: action.ordering };
    case SWITCH_CURRENT_FILTER:
      return { ...state, currentFilters: switchFilter(state.currentFilters, action.filter) };
    case SET_CALCULATION_DONE:
      return { ...state, calculationDone: action.done };
    case UPDATE_SERVICES_FILTER:
      return {
        ...state,
        currentFilters: {
          ...state.currentFilters,
          services: {
            ...state.currentFilters.services,
            touched: !action.initial,
            values: action.services,
          },
        },
      };
    case SET_AMO_ID:
      return { ...state, amoId: action.amoId };
    case LOCATION_CHANGE:
      if ((action.payload.location.pathname.startsWith('/details') || action.payload.location.pathname.startsWith('/invoice')) && action.payload.location.search) {
        return { ...state, currentRate: findRate(action.payload.location.search, state.results) };
      } else if (action.payload.location.pathname.startsWith('/calculation/') && action.payload.location.search) {
        return { ...state, currentRate: findRate(action.payload.location.search, state.results) };
      }
      return { ...state, currentRate: null };

    case SET_CURRENT_RATE:
      return { ...state, currentRate: findRateByRateResultId(action.payload, state.results) }

    case SET_RECALCULATION_RESULTS:
      return { ...state, recalcResults: action.results };
    case ADD_RECALCULATION_RESULTS:
      return { ...state, recalcResults: addRates(state.recalcResults, action.results) };
    case SET_RECALCULATION_RUNNING:
      return { ...state, recalculationRunning: action.running };
    case SET_RECALCULATION_DONE:
      return { ...state, recalculationDone: action.done };
    case SET_RECALCULATION_TASK_ID:
      return { ...state, recalcTaskId: action.taskId };

    case RESET_BASE_CALCULATION:
      return { ...initialState }

    default:
      return state;
  }
}
