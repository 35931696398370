import React, { useCallback, useState } from "react";
import moment from 'moment';
import useSelector from "../../../../../util/hooks/useSelector";
import { RootState } from "../../../../../reducers";
import DatePicker from "../../../../../components/DatePicker";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { FastCalculationContactFormData } from "../../../redux";
import useDispatch from "../../../../../util/hooks/useDispatch";
import { getShippingScheduler } from "../../../../creation/action_creators";
import classNames from "classnames";
import { RateCast } from "../../../../cast/types";

type Props = {
    currentRateCast?: RateCast | null,
    isSplitStyles?: boolean
}

const PickupDateField = ({
    currentRateCast,
    isSplitStyles
}: Props) => {

    const dispatch = useDispatch();

    const { t: _, i18n } = useTranslation('creation');

    const { currentRate } = useSelector((state: RootState) => state.calculation);
    const { availableCreationDates } = useSelector((state) => state.creation);
    const { cargoId } = useSelector((state) => state.cargo);
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const today = React.useRef(moment()).current;

    const {
        values, setFieldValue, errors, setFieldTouched
    } = useFormikContext<FastCalculationContactFormData>();

    const handleMonthChange = useCallback(
        (val: moment.Moment) => {
            const rateCode = currentRateCast ? currentRateCast.code : currentRate?.code;
            const deliveryService = currentRateCast ? currentRateCast.deliveryService : (currentRate?.originalDeliveryService || currentRate?.deliveryService as string);
            const maxDays = currentRateCast ? currentRateCast.maxDays : (currentRate?.maxDays as number);
            if (rateCode && deliveryService) {
                const startDate = val.clone().startOf('month').startOf('week');
                const endDate = startDate.clone().add(5, 'week').endOf('week');
                const { sender } = values;
                dispatch(getShippingScheduler({
                    startDate,
                    endDate,
                    days: maxDays,
                    deliveryService,
                    lat: (rateCode.indexOf("w2") > -1 && deliveryService === "dellin") ? sender.terminalId : sender.latitude,
                    long: (rateCode.indexOf("w2") > -1 && deliveryService === "dellin") ? sender.terminalId : sender.longitude,
                    tariffList: [deliveryService === "dellin" ? `${rateCode}::${cargoId}` : rateCode],
                }));
            }
        },
        [dispatch, currentRate, values, currentRateCast],
    );

    React.useEffect(() => {
        const rateCode = currentRateCast ? currentRateCast.code : currentRate?.code;
        const deliveryService = currentRateCast ? currentRateCast.deliveryService : (currentRate?.originalDeliveryService || currentRate?.deliveryService as string);
        const maxDays = currentRateCast ? currentRateCast.maxDays : (currentRate?.maxDays as number);
        if (rateCode && deliveryService) {
            const startDate = today.clone();
            const endDate = startDate.clone().add(1, 'month');
            const { sender } = values;
            dispatch(getShippingScheduler({
                startDate,
                endDate,
                days: maxDays,
                deliveryService,
                lat: (rateCode.indexOf("w2") > -1 && deliveryService === "dellin") ? sender.terminalId : sender.latitude,
                long: (rateCode.indexOf("w2") > -1 && deliveryService === "dellin") ? sender.terminalId : sender.longitude,
                tariffList: [deliveryService === "dellin" ? `${rateCode}::${cargoId}` : rateCode],
            })).then(
                ([firstAvailable]) => {
                    if (firstAvailable) setFieldValue('pickupDate', firstAvailable.date);
                },
            );
        }
    }, []);

    return (
        <div className={classNames("gx-d-flex gx-flex-column", {"gx-mt-auto": !isSplitStyles, "gx-w-100": isSplitStyles})}>
            <div className={classNames("custom-split-calculation-picker", {"gx-mt-2": !isSplitStyles, "is-split-m-0": isSplitStyles, "is-filled": isFocused || values.pickupDate, "is-focused": isFocused, "is-error": errors.pickupDate})}>
                <label className="custom-split-calculation-picker__label">Дата сбора</label>
                {
                    (currentRate || currentRateCast)
                    ?
                    <DatePicker
                        placeholder={today.format('DD.MM.YYYY')}
                        value={values.pickupDate?.locale(i18n.language)}
                        onChange={(val) => setFieldValue('pickupDate', val)}
                        defaultValue={today.locale(i18n.language)}
                        disabledDate={(d: any) => !d || !availableCreationDates.find((_) => d.isSame(_.date, 'day'))}

                        showToday={false}
                        format="DD.MM.YYYY"
                        className='gx-w-100 custom-split-calculation-picker__input'
                        allowClear={false}
                        // @ts-ignore
                        onPanelValueChange={handleMonthChange}
                        onFocus={() => {
                            setIsFocused(true);
                            setFieldTouched('pickupDate', true)
                        }}
                        onBlur={() => {
                            setIsFocused(false);
                        }}
                    />
                    :
                    <DatePicker
                        placeholder={today.format('DD.MM.YYYY')}
                        value={values.pickupDate?.locale(i18n.language)}
                        onChange={(val) => setFieldValue('pickupDate', val)}
                        defaultValue={today.locale(i18n.language)}
                        showToday={false}
                        format="DD.MM.YYYY"
                        className='gx-w-100 custom-split-calculation-picker__input'
                        allowClear={false}
                        onFocus={() => {
                            setIsFocused(true);
                            setFieldTouched('pickupDate', true)
                        }}
                        onBlur={() => {
                            setIsFocused(false);
                        }}
                    />
                }
            </div>
        </div>
    )
}

export { PickupDateField }